import api from '@/plugins/api'

export default {
    namespaced: true,
    state: {
        checkout: [],
    },
    getters: {
        checkout: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.checkout
        },
    },
    mutations: {
        INIT(state:any, checkout:any) {
            state.checkout = checkout
        }
    },
    actions: {
        ClientInit ({commit, state}:{commit:any, state:any}, app:any) {

        },
        Init: ({commit}:any, app:any) => {

        }
    }
}

