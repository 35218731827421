<template>
  <div id="apps">
    <div id="app">
      <notifications group="notification" position="top right">
        <template slot="body" slot-scope="props">
          <div>
            <a class="title">
              {{props.item.title}}
            </a>
            <a class="close" @click="props.close">
              <i class="fa fa-fw fa-close"></i>
            </a>
            <div v-html="props.item.text">
            </div>
          </div>
        </template>
      </notifications>
      <core-layout path="layouts" loading="loading" />
    </div>
  </div>
</template>

<script>
import api from "@/plugins/api";
import moment from "moment";
let cookie = require('vue-cookie');

export default {
  created() {
    try {
      let access_token = this.$store.getters['user/token'];
      if (access_token === undefined || access_token === null) {
        let locale = cookie.get("locale")
        if (!locale) {
          this.$i18n.locale = navigator.language ? navigator.language.slice(0, 2) : window.__env.default_locale
          cookie.set("locale", this.$i18n.locale, {expires: '1Y'})
        } else {
          this.$i18n.locale = locale
        }
        return this
      }
      api.get('/v2/users/check', {expand: 'main_spaces,phones'}, {bearer: access_token})
          .then(responce => {
            this.$store.commit('user/CurrentToken', access_token)
            this.$store.commit('user/UpdateIdentity', responce.data);
            this.$store.dispatch('events/LocationList');
            localStorage.setItem('currentUserId', responce.data.id);
            localStorage.removeItem('coworkingId');
            let locale = cookie.get('locale')
            if (locale) {
              this.$i18n.locale = locale;
            }
            else if (responce.data.lang) {
              this.$i18n.locale = responce.data.lang
            }
            else {
              this.$i18n.locale = navigator.language ? navigator.language.slice(0, 2) : window.__env.default_locale
            }
            moment.updateLocale('ru', {
                weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            });
            moment.locale(this.$i18n.locale);
            cookie.set("locale", this.$i18n.locale, {expires: '1Y'})
          }).catch(reason => {
            console.error(reason)
            clearToken();
          })
      return this
    } catch (e) {
      console.error(e);
      clearToken();
      return this
    }
  },
  clearToken() {
    cookie.delete('auth_key');
    this.$store.commit('user/UpdateIdentity', {});
    this.$store.commit('user/CurrentToken', null);
  }
}
</script>

<style lang="scss">
body {
  height: auto!important;
}
#app {
  font-family: 'PT Sans', 'Myriad Pro', Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
