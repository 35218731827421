<template>
  <b-modal v-model="showMobileModal"
           size="md"
           :centered="true"
           hide-header
           hide-footer>
    <div class="d-flex flex-column px-0 py-4">
      <div class="row">
        <div class="col-3">
          <img height="70" :src="logo.url" alt="logo">
        </div>
        <div class="col-9">
          <h2 class="font-weight-bolder">{{ setAppName }}</h2>
          <div class="m-0">
            <img class="star" src="@/assets/gold_star.svg" alt="stars">
            <img class="star" src="@/assets/gold_star.svg" alt="stars">
            <img class="star" src="@/assets/gold_star.svg" alt="stars">
            <img class="star" src="@/assets/gold_star.svg" alt="stars">
            <img class="star" src="@/assets/gold_star.svg" alt="stars">
          </div>
        </div>
      </div>
      <div class="row my-10">
        <div class="col-12">
          <h4>{{ $t('login.link_to_app') }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <b-button block variant="secondary" class="py-4" @click="closeMobileModal">{{ $t('login.close') }}</b-button>
        </div>
        <div class="col-6">
          <b-button block variant="primary" class="py-4" @click="goInstallApp">{{ $t('login.install') }}</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppMobileModal",
  data() {
    return {
      modal_informer: {},
      showMobileModal: false,
      mobileType: null
    }
  },
  mounted() {
    let isIOS = /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream;
    let isAndroid = /Android/.test(navigator.userAgent);
    if (isIOS && window.__env.install_url_ios) {
      this.mobileType = 'apple';
      this.showMobileModal = true;
    } else if (isAndroid && window.__env.install_url_android) {
      this.mobileType = 'android';
      this.showMobileModal = true;
    }
  },
  computed: {
    ...mapGetters({
      logo: 'main/logo',
    }),
    setAppName() {
      return window.__env.install_app_name;
    }
  },
  methods: {
    closeMobileModal() {
      this.showMobileModal = false;
    },
    goInstallApp() {
      this.showMobileModal = false;
      window.location.href = this.mobileType === 'apple' ? window.__env.install_url_ios
          : window.__env.install_url_android;
    }
  }
}
</script>

<style scoped>
  .star {
    width: 5%;
    height: 5%;
    margin-right: 2%;
  }
</style>
