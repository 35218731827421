import api from "@/plugins/api";

export default {
    namespaced: true,
    state: {
        list: [],
        page: 1,
        perPage: 20,
        totalCount: 0,
        loaded: false
    },
    getters: {
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.list
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
    },
    mutations: {
        UpdateList (state:any, data:any) {
            state.list = data
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        }
    },
    actions: {
        GetData ({commit, state}:{commit:any, state:any}, data:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/acts?expand=order,invoice', data)
                .then(responce => {
                    commit('UpdateList', responce.data);
                    commit('UpdatePage', responce.headers['x-pagination-current-page']);
                    commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                    commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                    commit('UpdateLoaded', true);
                })
            return this
        },
    }
}

