export default [
    {
        path: '/offers',
        title: 'offers',
        name: 'offers',
        link: true,
        component: () => import('./views/Offers.vue'),
        meta: {
            layout: 'lk',
            weight: 400,
            title: 'offers',
            icon: 'mdi-account',
        },
        children: [],
    },
    {
        path: '/offers/:id',
        title: 'offers_view',
        name: 'offers_view',
        link: false,
        component: () => import('./views/View.vue'),
        meta: {
            layout: 'lk',
            weight: 200,
            title: 'offers_view',
            icon: 'mdi-account',
            parent: 'activity',
        },
        children: [],
    }

]
