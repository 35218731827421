<template>
  <div>
    <div class="offcanvas offcanvas-right p-10" :class="show ? 'offcanvas-on' : ''">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">{{title}}</h3>
        <a href="#" class="btn btn-xs btn-icon btn-light primary--text" @click="showInfo = false">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->
      <slot></slot>
    </div>
    <div :class="show ? 'offcanvas-overlay' : ''" @click="showInfo = false"></div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  data: () => ({
    showInfo: false
  }),
  props: {
    title: {
      type: [String],
      default: ""
    },
    width: {
      type: String,
      default: "1000"
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  computed: {

  },
  watch: {
    show: {
      handler() {
        this.showInfo = this.show
      },
      deep: true,
    },
    showInfo: {
      handler() {
        this.$emit('update:show', this.showInfo);
      },
      deep: true,
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch('order/GetData', {})
    }
  }
}
</script>
