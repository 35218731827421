export default [
    {
        path: '/checkout/:id',
        title: 'checkout',
        name: 'checkout',
        link: false,
        component: () => import('./views/Checkout.vue'),
        meta: {
            layout: 'blank',
            weight: 100,
            title: 'checkout',
            icon: 'mdi-account',
        },
        children: [],
    }
]