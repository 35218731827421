<template>
  <!--begin::Tab Navs(for desktop mode)-->
  <ul class="header-tabs nav align-self-end font-size-lg" role="tablist">
    <!--begin::Item-->
    <router-link
        v-for="link in navigationLinks"
        :to="link.to" :key="link.to"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
    >
      <li class="nav-item with_menu">
        <a :href="href" @click="navigate" class="nav-link py-4 px-6"
           :class="[isActive && 'active', isExactActive && 'router-link-exact-active']">{{ link.title }}</a>
      </li>
    </router-link>
    <!--end::Item-->
  </ul>
  <!--begin::Tab Navs-->
</template>

<style>
.menu {
  padding: 0px;
  margin: 0px;
}
</style>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState({
      showMini: state => state.navigation.mini,
      navigationLinks: state => state.navigation.links
    })
  },
  mounted() {
  },
  methods: {},
  watch: {},
  data: () => {
    return {}
  },
}
</script>
