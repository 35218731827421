export default [
    {
        path: '/spaces',
        title: 'spaces',
        name: 'spaces',
        link: true,
        component: () => import('./views/Spaces.vue'),
        meta: {
            layout: 'lk',
            weight: 200,
            title: 'spaces',
            icon: 'mdi-account',
        },
        children: [],
    },
    {
        path: '/spaces/:id',
        title: 'spaces_view',
        name: 'spaces_view',
        link: false,
        component: () => import('./views/View.vue'),
        meta: {
            layout: 'lk',
            weight: 200,
            title: 'spaces_view',
            icon: 'mdi-account',
        },
        children: [],
    }
]