<template>
    <div id="core-view" class="h-100">
        <router-view/>
    </div>
</template>

<script>
    // Utilities
    import {
        mapMutations,
        mapState
    } from 'vuex'

    export default {
        name: 'CoreView',
        computed: {
            title() {
                return this.$route.meta.title || ''
            },
            subtitle() {
                return this.$route.meta.subtitle || ''
            },
            isBlank() {
                return this.$route.meta.layout == 'blank'
            },
            mode() {
                return this.$route.meta.mode || 'normal'
            },
            breadcrumbs() {
                let links = []
                links.push({
                    text: 'Главная',
                    disabled: false,
                    href: '/',
                })
                for (let link of this.$route.matched) {
                    links.push({
                        text: link.meta.title,
                        disabled: true,
                        href: link.path,
                    })
                }
                return links
            },
        },
        metaInfo() {
            return {
                title: 'Vue Material Dashboard by CreativeTim'
            }
        }
    }
</script>
