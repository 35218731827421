<template>
    <div class="notification is-danger">
        {{ message }}
    </div>
</template>

<script>
    export default {
        name: 'Test',
        data: () => ({
            message: "Include Test Component2"
        }),
    };
</script>