export default [
    {
        path: '/passes',
        title: 'passes',
        name: 'passes',
        link: true,
        component: () => import('./views/Passes.vue'),
        meta: {
            layout: 'lk',
            weight: 400,
            title: 'passes',
            icon: 'mdi-account'
        },
    }
]
