<template>
  <b-modal v-model="isActive" size="sm"
           @ok="selectCoworking"
           @hidden="hidesSetCoworkingModal"
           centered
           hide-header
           hide-footer
           no-close-on-backdrop>
    <b-form>
      <h3>{{ $t('coworking.Choose_Coworking') }}</h3>
      <div class="d-flex align-items-center px-4 py-2">
        <select class="form-control" v-model="coworking">
          <option v-for="coworking in coworkingList" :value="coworking.id">{{ coworking.name }}</option>
        </select>
      </div>
      <b-button size="sm" variant="primary" @click="selectCoworking"
                style="margin: 20px auto 0; display: block;"
                :disabled="onProgress">{{ $t('coworking.Select') }}</b-button>
    </b-form>
  </b-modal>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "SetCoworkingModal",
  data() {
    return {
      isActive: false,
      onProgress: false,
      coworking: ''
    }
  },
  props: {
    showCoworkingModal: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    showCoworkingModal(val) {
      this.isActive = val;
    },
    coworkingList(val) {
      let key = 'coworkingId_' + localStorage.getItem('currentUserId');
      let coworkingId = localStorage.getItem(key) || '';
      if (!coworkingId && val.length === 1) {
        localStorage.setItem('coworkingId_' + this.identity.id, val[0].id);
        document.location.reload();
      } else {
        this.coworking = this.coworkingList[0].id
      }
    }
  },
  computed: {
    ...mapGetters({
      coworkingList: 'spaces/coworking',
      identity: 'user/identity',
    })
  },
  methods: {
    selectCoworking() {
      this.onProgress = true;
      localStorage.setItem('coworkingId_' + this.identity.id, this.coworking);
      document.location.reload();
    },
    hidesSetCoworkingModal() {
      this.isActive = false;
      this.onProgress = false;
    },
  }
}
</script>

<style scoped>

</style>
