export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
        link: false,
        meta: {
            layout: 'blank',
            title: 'Авторизация',
            subtitle: 'Войти'
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('./views/Signup.vue'),
        link: false,
        meta: {
            layout: 'blank',
            title: 'Регистрация',
            subtitle: 'Регистрация'
        }
    },
    {
        path: '/password-recovery',
        name: 'password-recovery',
        component: () => import('./views/PasswordRecovery.vue'),
        link: false,
        meta: {
            layout: 'blank',
            title: 'Востановление пароля',
            subtitle: 'Востановление пароля'
        }
    },
    {
        path: '/profile-settings',
        name: 'profile-settings',
        component: () => import('./views/ProfileSettings.vue'),
        link: false,
        meta: {
            layout: 'lk',
            title: 'Настройки профиля',
            subtitle: 'Настройки профиля'
        }
    },
    {
        path: '/employees',
        name: 'employees',
        component: () => import('./views/Employees.vue'),
        link: false,
        meta: {
            layout: 'lk',
            title: 'Сотрудники',
            subtitle: 'Сотрудники'
        }
    },
    {
        path: '/notification-settings',
        name: 'notification-settings',
        component: () => import('./views/NotificationSettings.vue'),
        link: false,
        meta: {
            layout: 'lk',
            title: 'Настройки уведомлений',
            subtitle: 'Настройки уведомлений'
        }
    },
    {
        path: '/jwt-login',
        name: 'jwt-login',
        component: () => import('./views/JWTLogin.vue'),
        link: false,
        meta: {
            layout: 'lk',
            title: 'Вход через JWT',
            subtitle: 'Вход через JWT'
        }
    }
]
