import api from "@/plugins/api";

export default {
    namespaced: true,
    state: {
        list: {},
        locations: {},
        locationsFilterList: {},
        info: {},
        page: 0,
        perPage: 20,
        totalCount: 0,
        loaded: false
    },
    getters: {
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.list
        },
        locations: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.locations
        },
        locationsFilterList: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.locationsFilterList
        },
        info: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.info
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
    },
    mutations: {
        UpdateList (state:any, data:any) {
            state.list = data
        },
        UpdateLocation (state:any, data:any) {
            state.locations = data
        },
        UpdateLocationsFilterList (state:any, data:any) {
            state.locationsFilterList = data
        },
        UpdateInfo (state:any, data:any) {
            state.info = data
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        }
    },
    actions: {
        GetData ({commit, state}:{commit:any, state:any}, data:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/tariffs', {
                // 'location_id': responce.data.location_ids.join(','),
                'location_id': data.location_id ? data.location_id : null,
                'visible': 1,
                'status': 1,
                'page': data.page || 1,
                'per-page': 21,
                'coworking_id': data.coworking,
                'expand': ['location_ids'].join(',')
            }).then(responce => {
                commit('UpdateList', responce.data);
                commit('UpdatePage', responce.headers['x-pagination-current-page']);
                commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);

                let locations = []
                for (let i in responce.data) {
                    locations = locations.concat(responce.data[i].location_ids);
                }
                locations = locations.reverse().filter(function (e, i, arr) {
                    return arr.indexOf(e, i+1) === -1;
                }).reverse();
                api.get('/v2/locations', {
                    'ids_filter': locations.join(','),
                    'visible': 1,
                    'page': 1,
                    'per-page': 1000,
                    'fields': ['id','name'].join(',')
                }).then(responce => {
                    commit('UpdateLocation', responce.data);
                    commit('UpdateLoaded', true);
                })
            })
            return this
        },
        GetLocation ({commit, state}:{commit:any, state:any}, data:any) {
            // api.get('/v2/users/check?expand=main_spaces', data)
            //     .then(responce => {
                    api.get('/v2/locations', {
                        // 'ids': responce.data.location_ids.join(','),
                        'visible': 1,
                        'page': 1,
                        'per-page': 1000,
                        'fields': ['id','name'].join(','),
                        'coworking_ids': [data.coworking].join(','),
                    }).then(responce => {
                        commit('UpdateLocationsFilterList', responce.data);
                    })
                // })
            return this
        },
        GetInfo ({commit, state}:{commit:any, state:any}, id:any) {
            return new Promise((resolve, reject) => {
                api.get('/v2/tariffs/' + id, {
                    'expand': ['location_ids'].join(',')
                }).then(responce => {
                    resolve(responce.data);
                    commit('UpdateInfo', responce.data);
                })
            })
        },
        GetLocationSimpleInfo({commit, state}:{commit:any, state:any}, id:any) {
            return new Promise((resolve) => {
                api.get('/v2/locations/' + id)
                    .then(responce => {
                        resolve(responce.data);
                    })
            })
        }
    }
}

