import api from "@/plugins/api";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        list: {},
        page: 0,
        perPage: 20,
        totalCount: 0,
        loaded: false,
        locations: [],
        topics: [],
        info: {},
    },
    getters: {
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.list
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
        locations: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.locations
        },
        topics: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.topics
        },
        info: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.info
        },
    },
    mutations: {
        UpdateList (state:any, data:any) {
            state.list = data
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        },
        UpdateLocations (state:any, locations:any) {
            state.locations = locations
        },
        UpdateTopics(state:any, topics:any) {
            state.topics = topics
        },
        UpdateInfo (state:any, data:any) {
            state.info = data
        },
    },
    actions: {
        LocationList ({commit, rootState}:any, ids:any) {
            return new Promise((resolve, reject) => {
                api.get('/v2/locations', {
                    // 'ids_filter': rootState.user.identity.location_ids ? rootState.user.identity.location_ids.join(',') : '',
                    'ids_filter': ids,
                    'visible': 1,
                    'page': 1,
                    'per-page': 1000,
                    'fields': ['id', 'name'].join(',')
                }).then(responce => {
                    resolve(responce.data)
                    commit('UpdateLocations', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        TopicsList ({commit}:{commit:any}) {
            api.get('/v2/coworking-events/topics')
                .then(responce => {
                    commit('UpdateTopics', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        GetData ({commit, state}:{commit:any, state:any}, data:any) {
            return new Promise((resolve, reject) => {
                commit('UpdateLoaded', false);
                api.get('/v2/coworking-events', {
                    'name': data.name,
                    'location_id': data.location_id,
                    'topic_id': data.topic_id,
                    'type': data.type_id,
                    'is_user_registered': data.intention,
                    'start[from]': data.from ? data.from : moment().format(),
                    'start[to]': data.to ? data.to : moment().add('months', 12).format(),
                    // 'end[from]': moment().format(), // Why this here?
                    'page': data.page,
                    'per-page': data['per-page']
                })
                    .then(responce => {
                        resolve(responce.data);
                        // console.log(responce.data.filter((item: { type: any; }) => item.type == data.type_id));
                        // commit('UpdateList', responce.data.filter((item: { type: any; }) => item.type == data.type_id));
                        commit('UpdateList', responce.data);
                        commit('UpdatePage', responce.headers['x-pagination-current-page']);
                        commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                        commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                        commit('UpdateLoaded', true);
                    })
                return this
            })
        },
        GetInfo ({commit, state}:{commit:any, state:any}, id:any) {
            commit('UpdateLoaded', false);
            api.get('/v2/coworking-events/' + id)
                .then(responce => {
                    commit('UpdateInfo', responce.data);
                    commit('UpdateLoaded', true);
                })
            return this
        },
    }
}

