import api from "@/plugins/api";

export default {
    namespaced: true,
    state: {
        list: {},
        page: 0,
        perPage: 20,
        totalCount: 0,
        loaded: false,
        offers: [],
        partners: [],
        tags: [],
        info: {},
        locations: [],
    },
    getters: {
        list: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.list
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
        offers: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.offers
        },
        partners: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.partners
        },
        tags: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.tags
        },
        info: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.info
        },
        locations: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.locations
        },

    },
    mutations: {
        UpdateList (state:any, data:any) {
            state.list = data
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, loaded:any) {
            state.loaded = loaded
        },
        UpdateOffers(state:any, offers:any) {
            state.offers = offers
        },
        UpdatePartners(state:any, partners:any) {
            state.partners = partners
        },
        UpdateTags (state:any, tags:any) {
            state.tags = tags
        },
        UpdateInfo (state:any, info:any) {
            state.info = info
        },
        UpdateLocations (state:any, locations:any) {
            state.locations = locations
        },
    },
    actions: {
        OffersList ({commit}:{commit:any}, filters:any) {
            let data = { expand: 'locations,tags', filters }
            commit('UpdateLoaded', false);
            api.get('/v2/offers', {
                'tags_ids': [filters.tags_ids].join(','),
                'partner_name': filters.partner_name,
                'location_ids': [filters.location_ids].join(','),
                expand: 'locations,tags',
            })
                .then(responce => {
                    commit('UpdateOffers', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        PartnersList ({commit}:{commit:any}) {
            commit('UpdateLoaded', false);
            api.get('/v2/offers/partners')
                .then(responce => {
                    commit('UpdatePartners', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        TagsList ({commit}:{commit:any}, query:any) {
            api.get('/v2/offers/tags', {
                q: query
            })
                .then(responce => {
                    commit('UpdateTags', responce.data);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        GetInfo ({commit}:{commit:any}, id:any) {
            let data = {expand: 'locations,tags'}
            commit('UpdateLoaded', false);
            api.get('/v2/offers/' + id, data)
                .then(responce => {
                    commit('UpdateInfo', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        LocationList ({commit}:{commit:any}, ids:string) {
            return new Promise((resolve, reject) => {
                commit('UpdateLoaded', false);
                api.get('/v2/locations', {
                    'ids_filter': ids,
                    'fields': ['id', 'name'].join(',')
                }).then(responce => {
                    resolve(responce.data);
                    commit('UpdateLocations', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        GetData ({commit}:{commit:any}, data:any) {
            api.get('/v2/orders', data)
                .then(responce => {
                    commit('UpdateList', responce.data);
                    commit('UpdatePage', responce.headers['x-pagination-current-page']);
                    commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                    commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                })
            return this
        },
    }
}

