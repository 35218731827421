let cookie = require('vue-cookie');
import api from '@/plugins/api'

export default {
    namespaced: true,
    state: {
        token: null,
        identity: {},
        firstLettersFio: '',
        availableResources: {
            deposits: [],
            resources: [],
            spacepass: []
        },
        userResources: [],
        arrayUniqueByKey: null,
        companies: [],
        employees: [],
        errorMessage: null,
        page: 0,
        perPage: 20,
        totalCount: 0,
        loaded: false
    },
    getters: {
        isAuthenticated: (state:any) => {
            return !!state.token || !!localStorage.getItem('authToken');
        },
        token: (state:any) => state.token,
        firstLettersFio: (state:any) => state.firstLettersFio,
        phoneByConfirmed: (state:any, getters:any, rootState:any, rootGetters:any) => {
            // console.log('get list')
            // return state.identity
            let phone = null
            if (state.identity) {
                for (let index in state.identity.phones) {
                    if (state.identity.phones[0] && state.identity.phones[0].is_confirmed) {
                        phone = state.identity.phones[0].phone
                    }
                }
            }
            return phone
        },
        identity: (state:any, getters:any, rootState:any, rootGetters:any) => {
            // console.log('get list')
            // return state.identity
            return JSON.parse(JSON.stringify(state.identity))
        },
        availableUserResources: (state:any) => {
            return state.userResources;
        },
        availableResourceIds: (state:any) => {
            return state.arrayUniqueByKey;
        },
        availableCompaniesResources: (state:any) => {
            return state.availableResources;
        },
        companiesList: (state:any) => {
            return state.companies;
        },
        employeesList: (state:any) => {
            return state.employees;
        },
        errorMessage: (state:any) => {
            return state.errorMessage;
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
    },
    mutations: {
        InitializeStore (state:any) {
            if (localStorage.getItem('authToken')) {
                state.token = localStorage.getItem('authToken');
            }
        },
        UpdateGet (state:any, data:any) {
            // console.log('mutations.UpdateList', data)
            state.get = data
        },
        UpdateIdentity (state:any, data:any) {
            if (data.phones) {
                data.phones.sort((a, b) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))
            }
            else {
                data.phones = []
            }
            state.identity = data
            if (data.fio) {
                state.firstLettersFio = data.fio.split(' ').map(x => x[0]).join('');
            }
            else {
                state.firstLettersFio = ''
            }
        },
        CurrentToken (state:any, token:any) {
            // console.log('mutations.CurrentToken', accessToken)
            state.token = token
            if (!token) {
                localStorage.removeItem('authToken');
            } else {
                localStorage.setItem('authToken', token);
            }
        },
        UpdateAvailableResources (state:any, data:any) {
            state.availableResources = data;

            let dep:any = data.deposits.find(item => item.owner_type === 'user');
            if (dep) dep.resource_type = 'deposit';
            let res:any = data.resources.find(item => item.owner_type === 'user');
            if (res) res.resource_type = 'hours';
            state.userResources = [];
            state.userResources.push(dep, res);
            // state.userResources = [...dep, ...res];

            let depIds:any = data.deposits.map(item => item.owner_type !== 'user' ? item.owner : null);
            let resIds:any = data.resources.map(item => item.owner_type !== 'user' ? item.owner : null);
            let arrayIds = [...depIds, ...resIds];
            arrayIds = arrayIds.filter(item => item !== null);
            state.arrayUniqueByKey = [...new Map(state.identity.companies.map(item =>
                [item['id'], item])).values()];
        },
        UpdateCompanies (state:any, data:any) {
            state.companies = data;
        },
        UpdateEmployees (state:any, data:any) {
            state.employees = data;
        },
        UpdateErrorMessage (state:any, data:any) {
            state.errorMessage = data;
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        },
        UpdateEmployeeHasCard (state:any, data:any) {
            let employee = state.employees.find(item => item.id === data.id);
            if (employee) {
                employee.card = data.card;
            }
        }
    },
    actions: {
        ClientInit ({commit, state}:{commit:any, state:any}, app:any) {

        },
        AuthenticatedByCookie ({commit, state}:{commit:any, state:any}, app:any) {
            return new Promise((resolve, reject) => {
                if (state.token) {
                    resolve(state.token)
                }
                let access_token = cookie.get('auth_key')
                if (access_token == undefined) {
                    reject(403)
                }
                api.get('/v2/users/check', {expand: 'main_spaces,phones'}, {bearer: access_token})
                    .then(responce => {
                        commit('CurrentToken', access_token);
                        commit('UpdateIdentity', responce.data);
                        resolve(responce.data)
                    }).catch(reason => {
                        cookie.delete('auth_key');
                        commit('CurrentToken', null)
                        commit('UpdateIdentity', {});
                        reject(reason)
                    })
            })
        },
        GetAvailableResources: ({commit}:any, data: any) => {
            api.get('/v2/users/available-resources', {
                resource_id: data.resource_id,
                start: data.start,
                end: data.end,
                expand: 'owner'
            }).then(res => {
                commit('UpdateAvailableResources', res.data);
            }).catch(reason => {
                console.error('reason', reason)
            })
        },
        GetCompanies: ({commit}:any) => {
            return new Promise((resolve, reject) => {
                api.get('/v2/companies', {
                    // expand: 'staff'
                }).then(res => {
                    resolve(res.data);
                    commit('UpdateCompanies', res.data);
                }).catch(reason => {
                    console.error('reason', reason)
                })
            });
        },
        GetCompanyEmployees: ({commit}:any, data:any) => {
            commit('UpdateLoaded', false);
            return new Promise((resolve, reject) => {
                api.get('/v2/companies/' + data.id + '/staff', {
                    q: data.q,
                    page: data.page,
                    'per-page': data.all !== null ? 500 : data.perPage,
                }).then(res => {
                    resolve(res.data);
                    if (data.all === null) {
                        commit('UpdateErrorMessage', null);
                        commit('UpdateEmployees', res.data);
                        commit('UpdatePage', res.headers['x-pagination-current-page']);
                        commit('UpdatePerPage', res.headers['x-pagination-per-page']);
                        commit('UpdateTotalCount', res.headers['x-pagination-total-count']);
                    }
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    if (reason.response.data.error) {
                        commit('UpdateErrorMessage', reason.response.data.error.message);
                        commit('UpdateEmployees', []);
                    }
                    console.error('reason', reason)
                    commit('UpdateLoaded', true);
                })
            });
        },
        cardInfo: async ({commit}, companyID) => {
            const response = await api.get('/sok/card/info?company=' + companyID)
            return response.data
        },
        hasCardKey: async ({commit}, userIDs) => {
            const response = await api.get('/sok/card/has?users=' + userIDs.join(','))
            return response.data
        }

    }
}

