import api from '@/plugins/api'

export default {
    namespaced: true,
    state: {
        summary: [],
        detailed: [],
        page: 0,
        perPage: 20,
        totalCount: 0,
        companies: [],
        loaded: false,
    },
    getters: {
        summary: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.summary
        },
        detailed: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.detailed
        },
        companies: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.companies
        },
        page: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.page
        },
        perPage: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.perPage
        },
        totalCount: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.totalCount
        },
        loaded: (state:any, getters:any, rootState:any, rootGetters:any) => {
            return state.loaded
        },
    },
    mutations: {
        INIT_SUMMARY(state:any, summary:any) {
            state.summary = summary
        },
        INIT_DETAILED(state:any, detailed:any) {
            state.detailed = detailed
        },
        INIT_COMPANIES(state:any, companies:any) {
            state.companies = companies
        },
        UpdatePage (state:any, data:any) {
            state.page = data
        },
        UpdatePerPage (state:any, data:any) {
            state.perPage = data
        },
        UpdateTotalCount (state:any, data:any) {
            state.totalCount = data
        },
        UpdateLoaded (state:any, data:any) {
            state.loaded = data
        },
    },
    actions: {
        SummaryInit: ({commit}:any, data: any) => {
            commit('UpdateLoaded', false);
            api.get('/v2/attendance/summary', {
                'date_start': data.from,
                'date_end': data.to,
                'company_id': data.company_id
                // 'date_start': '01.01.2020',
                // 'date_end': '02.01.2020',
                // 'company_id': 1
            })
                .then(responce => {
                    commit('INIT_SUMMARY', responce.data);
                    commit('UpdatePage', responce.headers['x-pagination-current-page']);
                    commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                    commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        DetailedInit: ({commit}:any, data: any) => {
            commit('UpdateLoaded', false);
            api.get('/v2/attendance/detailed', {
                'date_start': data.from,
                'date_end': data.to,
                'company_id': data.company_id
                // 'date_start': '01.01.2020',
                // 'date_end': '02.01.2020',
                // 'company_id': 1
            })
                .then(responce => {
                    commit('INIT_DETAILED', responce.data);
                    commit('UpdatePage', responce.headers['x-pagination-current-page']);
                    commit('UpdatePerPage', responce.headers['x-pagination-per-page']);
                    commit('UpdateTotalCount', responce.headers['x-pagination-total-count']);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        },
        CompaniesInit: ({commit}:any) => {
            commit('UpdateLoaded', false);
            api.get('/v2/companies', {})
                .then(responce => {
                    commit('INIT_COMPANIES', responce.data);
                    commit('UpdateLoaded', true);
                }).catch(reason => {
                    console.error('reason', reason)
            })
        }
    }
}

