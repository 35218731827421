export default [
    {
        path: '/order',
        title: 'orders',
        name: 'order',
        link: true,
        component: () => import('./views/Orders.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'orders_bookings',
            icon: 'mdi-account',
            menu: [
                {
                    path: '/order',
                    title: 'orders',
                    name: 'order',
                    link: true,
                },
                {
                    path: '/order/bookings',
                    title: 'bookings',
                    name: 'bookings',
                    link: true,
                }
            ],
        },
        children: [],
    },
    {
        path: '/order/bookings',
        title: 'bookings',
        name: 'bookings',
        link: false,
        component: () => import('./views/Bookings.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'bookings',
            icon: 'mdi-account',
            parent: 'order'
        },
    }
]