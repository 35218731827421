export default [
    {
        path: '/invoice',
        title: 'invoices',
        name: 'invoice',
        link: true,
        component: () => import('./views/Invoice.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'invoices',
            icon: 'mdi-account',
            menu: [
                {
                    path: '/invoice',
                    title: 'invoices',
                    name: 'invoices',
                    link: true,
                },
                {
                    path: '/invoice/acts',
                    title: 'acts',
                    name: 'acts',
                    link: true,
                }
            ],
        },
        children: [],
    },
    {
        path: '/invoice/acts',
        title: 'acts',
        name: 'acts',
        link: false,
        component: () => import('./views/Acts.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'acts',
            icon: 'mdi-account',
            parent: 'invoice'
        },
    }
]