<template>
  <component :is="currentLayout"/>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: 'default'
    },
    loading: {
      type: String,
      default: null
    },
    path: {
      type: String,
      default: 'layouts'
    }
  },

  data () {
    return {
      layoutName: 'default'
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler (route) {
        const newLayout = route.meta.layout
        if (!newLayout && !this.$route.name) { this.layoutName = this.loading; return }
        if (!newLayout) { this.layoutName = this.layout || 'default'; return }
        this.layoutName = newLayout
      }
    }
  },

  computed: {
    currentLayout () {
      if (!this.layoutName) return
      const ln = this.layoutName
      return () => import(/* webpackChunkName: "layout-[request]" */ `@/${this.path}/${ln}.vue`)
    }
  }
}
</script>
