<template>
  <b-modal v-model="showAlert"
           size="md"
           @ok="goCancel"
           @hidden="goCancel"
           ok-only
           :centered="true"
           :title="title"
           :ok-variant="closeButtonColor"
           :ok-title="closeButtonTitle">
    <div class="d-flex flex-column align-items-center">
      <div v-if="icon=='fail'" class="symbol symbol-50 symbol-light-danger mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="ban" class="text-danger icon-lg"/>
        </div>
      </div>
      <div v-if="icon=='ok'" class="symbol symbol-50 symbol-light-success mb-4 flex-shrink-0">
        <div class="symbol-label">
          <font-awesome-icon icon="check" class="text-success icon-lg"/>
        </div>
      </div>
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import api from "@/plugins/api";

export default {
  data: () => ({
    showAlert: false
  }),
  props: {
    title: {
      type: [String],
      default: ""
    },
    icon: {
      type: [String],
      default: ""
    },
    closeButtonColor: {
      type: [String],
      default: ""
    },
    closeButtonTitle: {
      type: [String],
      default: ""
    },
    alertOnCancel: {
      type: Function,
      default: function() {}
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  computed: {

  },
  watch: {
    show: {
      handler() {
        this.showAlert = this.show
      }
    },
    showAlert: {
      handler() {
        this.$emit('update:show', this.showAlert);
      }
    },
  },
  methods: {
    goCancel() {
      this.showAlert = false
      this.alertOnCancel(this.showAlert)
    },
  }
}
</script>
