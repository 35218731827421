export default [
    {
        path: '/dashboard',
        title: 'dashboard',
        name: 'dashboard',
        link: true,
        component: () => import('./views/Index.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'dashboard',
            icon: 'mdi-account',
            subtitle: ''
        }
    },
]