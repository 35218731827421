<template>
    <div class="home pa-4">
        Empty page
    </div>
</template>

<script>
export default {
  data: () => {
    return {
    }
  },
  mounted() {
    this.$router.push('/dashboard')
  }
}
</script>
