<template>
  <div class="overflow-auto">
    <table class="table">
      <thead>
      <tr>
        <th v-for="(column, index) in columnsList" :key="index" scope="col"
            @click="clickHeaderEvent(index, column)"
            class=" cursor"
            :class="sortingMode[index] ? 'text-primary' : 'text-muted'">
          {{ $t(column.text) }} <i v-if="sortingMode[index]" :class="sortingMode[index]"></i>
        </th>
      </tr>
      </thead>
      <tbody v-if="loaded">
      <tr v-for="(item, index) in items" :key="index">
        <!-- {{ item }} -->
        <td v-for="(column, i) in columnsList" :key="i" class="p-3 align-middle" :class="column.class">
          <slot v-if="column.value === '#'">{{ index + 1 }}</slot>
          <slot v-else-if="column.value">{{ deepFind(item, column.value, column) }}</slot>
          <slot v-else-if="column.slot" :name="column.slot"
                :item="item" :column="column" :deepFind="deepFind" :getValuesItem="getValuesItem"></slot>
        </td>
      </tr>
      </tbody>
      <tbody v-if="!loaded">
      <tr v-for="index in filter['per-page']" :key="index">
        <td v-for="(column, index) in columnsList" :key="index" class="p-3 align-middle">
          <PuSkeleton></PuSkeleton>
        </td>
      </tr>
      </tbody>
    </table>

    <!--begin::Pagination-->
    <div v-if="showFooter" class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex flex-wrap py-2 mr-3">
        <div v-if="totalCount && (maxPage > 1)">
          <a v-if="filter.page > 1" @click="filter.page = 1"
             class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-back icon-xs"></i></a>

          <a v-if="filter.page > 1" @click="filter.page = filter.page - 1"
             class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page - 1}}</a>
          <a class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1">{{filter.page}}</a>
          <a v-if="filter.page < totalCount / filter['per-page']" @click="filter.page = filter.page + 1"
             class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page + 1}}</a>

          <a v-if="filter.page < maxPage" @click="filter.page = maxPage"
             class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
        </div>
      </div>
      <div class="d-flex align-items-center py-3">
        <select v-model="filter['per-page']"
                class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light" style="width: 75px;">
          <option v-for="_page in pages" :key="_page.id" :value="_page.key">{{ _page.value }}</option>
        </select>
        <span class="text-muted">{{ $t('datatable.view_from_to', {from: items_count(), all: totalCount}) }}</span>
      </div>
    </div>
    <!--end:: Pagination-->
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  data: () => ({
    arrowUp: 'ki ki-arrow-up',
    arrowDown: 'ki ki-arrow-down',
    sorting: {},
    sortingMode: {},
    columnsList: [],
    maxPage: 1,
    pages: [
      {key: 1, value: 1},
      {key: 5, value: 5},
      {key: 10, value: 10},
      {key: 20, value: 20},
      {key: 50, value: 50},
      {key: 100, value: 100},
    ]
  }),
  props: {
    loaded: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: function() {
        return {}
      }
    },
    columns: {
      type: Array,
      default:  function() {
        return []
      }
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    items: {
      type: [Array, Object],
      default:  function() {
        return []
      }
    }
  },
  mounted() {
    this.columnsList = this.columns
  },
  methods: {
    items_count: function () {
      this.maxPage = Math.ceil(this.totalCount / this.filter['per-page'])
      return Object.keys(this.items).length;
    },
    clickHeaderEvent(index, column) {
      console.log(column)
      if (column.sort === false) {
        return
      }
      let key = String(index);
      let mode = this.sorting[key] || 0;
      console.log(mode);
      this.sorting = {};
      this.sortingMode = {};
      this.sorting[key] = Number(mode) + 1;
      if (this.sorting[key] < 0 || this.sorting[key] > 2) {
        this.sorting[key] = 0;
      }
      for (index in this.columnsList) {
        if (this.columnsList[index].sort !== false) {
          this.columnsList[index].sort = 0;
        }
      }
      this.columnsList[key].sort = this.sorting[key];
      this.$emit('update:columns', JSON.parse(JSON.stringify(this.columnsList)));
      switch (this.sorting[key]) {
        case 0:
          this.sortingMode[key] = '';
          break;
        case 1:
          this.sortingMode[key] = this.arrowUp;
          break;
        case 2:
          this.sortingMode[key] = this.arrowDown;
          break;
        default:
          this.sortingMode[key] = '';
          break;
      }
    },
    deepFind(obj, path, column) {
      let paths = path.split('.')
      let current = obj
      for (let i = 0; i < paths.length; ++i) {
        if (current[paths[i]] == undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }

      if (column.values) {
        current = this.getValuesItem(current, column).name || current;
      }

      return current;
    },
    getValuesItem(value, column) {
      let _value = {}
      if (column.values) {
        for (let i = 0; i < column.values.length; ++i) {
          if (column.values[i].id == value) {
            _value = column.values[i]
          }
        }
      }
      return _value
    }
  }
}
</script>

<style>
.cursor {
  cursor: pointer;
}
</style>
