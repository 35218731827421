export default [
    {
        path: '/attendance',
        title: 'summary_report',
        name: 'summary_report',
        link: false,
        component: () => import('./views/Summary.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'summary_report',
            icon: 'mdi-account',
            menu: [
                {
                    path: '/attendance',
                    title: 'summary_report',
                    name: 'order',
                    link: true,
                },
                {
                    path: '/attendance/detailed',
                    title: 'detailed_report',
                    name: 'detailed_report',
                    link: true,
                }
            ],
        },
        children: [],
    },
    {
        path: '/attendance/detailed',
        title: 'detailed_report',
        name: 'detailed_report',
        link: false,
        component: () => import('./views/Detailed.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'detailed_report',
            icon: 'mdi-account',
            parent: 'attendance'
        },
    }
]