export default [
    {
        path: '/events',
        title: 'events',
        name: 'events',
        link: true,
        component: () => import('./views/Events.vue'),
        meta: {
            layout: 'lk',
            weight: 400,
            title: 'events',
            icon: 'mdi-account',
            menu: [
                {
                    path: '/events',
                    title: 'events',
                    name: 'events',
                    link: true,
                },
                // {
                //     path: '/events/requests',
                //     title: 'requests',
                //     name: 'requests',
                //     link: false,
                // },
                {
                    path: '/events/subscribes',
                    title: 'subscribes',
                    name: 'subscribes',
                    link: true,
                }
            ],
        },
        children: [],
    },
    {
        path: '/events/requests',
        title: 'requests',
        name: 'requests',
        link: false,
        component: () => import('./views/Requests.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'requests',
            icon: 'mdi-account',
            parent: 'activity',
            menu: [
                {
                    path: '/events',
                    title: 'events',
                    name: 'events',
                    link: true,
                },
                // {
                //     path: '/events/requests',
                //     title: 'requests',
                //     name: 'requests',
                //     link: false,
                // },
                {
                    path: '/events/subscribes',
                    title: 'subscribes',
                    name: 'subscribes',
                    link: true,
                }
            ],
        },
    },
    {
        path: '/events/subscribes',
        title: 'subscribes',
        name: 'subscribes',
        link: false,
        component: () => import('./views/Subscribes.vue'),
        meta: {
            layout: 'lk',
            weight: 100,
            title: 'subscribes',
            icon: 'mdi-account',
            parent: 'activity',
            menu: [
                {
                    path: '/events',
                    title: 'events',
                    name: 'events',
                    link: true,
                },
                // {
                //     path: '/events/requests',
                //     title: 'requests',
                //     name: 'requests',
                //     link: false,
                // },
                {
                    path: '/events/subscribes',
                    title: 'subscribes',
                    name: 'subscribes',
                    link: true,
                }
            ],
        },
    },
    {
        path: '/events/:id',
        title: 'events_view',
        name: 'events_view',
        link: false,
        component: () => import('./views/View.vue'),
        meta: {
            layout: 'lk',
            weight: 200,
            title: 'events_view',
            icon: 'mdi-account',
            parent: 'activity',
            menu: [
                {
                    path: '/events',
                    title: 'events',
                    name: 'events',
                    link: true,
                },
                {
                    path: '/events/subscribes',
                    title: 'subscribes',
                    name: 'subscribes',
                    link: true,
                }
            ],
        },
        children: [],
    }
]
