import Vue from 'vue'
import axios from 'axios'
import store from "@/store";
const qs = require('qs');
let cookie = require('vue-cookie');

class Api {
    public readonly X_SOURCE_LK = 'lk';
    public app: any;
    public name: any;
    public store: any;
    public baseUrl: any;
    public _axios: any;

    constructor() {
        // console.log(window["__env"])
        this.baseUrl = window["__env"].api.url;
        this._axios = axios.create({
            timeout: window["__env"].api.timeout,
            baseURL: this.baseUrl,
            headers: {
                'X-Company-Header': 'SpacePass',
                // 'Set-Cookie': 'HttpOnly;Secure;SameSite=Strict',
            }
        })

        this._axios.defaults.headers.post['Content-Type'] = 'application/json';
        this._axios.defaults.headers.delete['Content-Type'] = 'application/json';
        this._axios.defaults.headers.put['Content-Type'] = 'application/json';
        this._axios.defaults.headers.patch['Content-Type'] = 'application/json';
        this._axios.defaults.headers.common['X-Source'] = 'lk';
        let locale = cookie.get("locale")
        if (locale) {
            this._axios.defaults.headers.common['language'] = locale;
        }
        else {
            this._axios.defaults.headers.common['language'] = navigator.language ? navigator.language.slice(0, 2) : window["__env"].default_locale
        }

        if (store && 'user/token' in store.getters && store.getters['user/token']) {
            this._axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['user/token']
        }
    }

    configure(options: any, data: any) {
        let config: any = {}

        // `method` is the request method to be used when making the request
        if (options.method) config.method = options.data || 'get' // default

        // `url` is the server URL that will be used for the request
        if (options.url) config.url = options.url || undefined

        if (options.params) config.params = options.params || undefined

        // `data` is the data to be sent as the request body
        // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
        // When no `transformRequest` is set, must be of one of the following types:
        // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
        // - Browser only: FormData, File, Blob
        // - Node only: Stream, Buffer
        if (options.data && options.type == undefined) config.data = options.data || {}
        if (options.data && options.type == 'json') config.data = options.data || {}
        if (options.data && options.type == 'form') config.data = qs.stringify(options.data || {})


        // `timeout` specifies the number of milliseconds before the request times out.
        // If the request takes longer than `timeout`, the request will be aborted.
        if (options.timeout) config.timeout = options.timeout || 1000 // default is `0` (no timeout)

        // `responseType` indicates the type of data that the server will respond with
        // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
        // browser only: 'blob'
        if (options.responseType) config.responseType = options.responseType || 'json' // default

        // `responseEncoding` indicates encoding to use for decoding responses
        // Note: Ignored for `responseType` of 'stream' or client-side requests
        if (options.responseEncoding) config.responseEncoding = options.responseEncoding || 'utf8' // default

        // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
        if (options.xsrfCookieName) config.xsrfCookieName = options.xsrfCookieName || 'XSRF-TOKEN' // default

        // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
        if (options.xsrfHeaderName) config.xsrfHeaderName = options.xsrfHeaderName || 'X-XSRF-TOKEN' // default

        // `maxRedirects` defines the maximum number of redirects to follow in node.js.
        // If set to 0, no redirects will be followed.
        if (options.maxRedirects) config.maxRedirects = options.maxRedirects || 5 // default

        // 'proxy' defines the hostname and port of the proxy server.
        // You can also define your proxy using the conventional `http_proxy` and
        // `https_proxy` environment variables. If you are using environment variables
        // for your proxy configuration, you can also define a `no_proxy` environment
        // variable as a comma-separated list of domains that should not be proxied.
        // Use `false` to disable proxies, ignoring environment variables.
        // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
        // supplies credentials.
        // This will set an `Proxy-Authorization` header, overwriting any existing
        // `Proxy-Authorization` custom headers you have set using `headers`.
        if (options.proxy) config.proxy = options.proxy || undefined

        // `validateStatus` defines whether to resolve or reject the promise for a given
        // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
        // or `undefined`), the promise will be resolved; otherwise, the promise will be
        // rejected.
        if (options.validateStatus) config.validateStatus = options.validateStatus || function (status:number) {
            return status >= 200 && status < 300;
        }  // default

        // `onUploadProgress` allows handling of progress events for uploads
        // Do whatever you want with the native progress event
        if (options.onUploadProgress) config.onUploadProgress = options.onUploadProgress || undefined

        // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
        // This will set an `Authorization` header, overwriting any existing
        // `Authorization` custom headers you have set using `headers`.
        // Please note that only HTTP Basic auth is configurable through this parameter.
        // For Bearer tokens and such, use `Authorization` custom headers instead.
        if (options.auth) config.auth = options.auth || undefined

        // `adapter` allows custom handling of requests which makes testing easier.
        // Return a promise and supply a valid response (see lib/adapters/README.md).
        if (options.adapter) config.adapter = options.adapter || undefined

        // `withCredentials` indicates whether or not cross-site Access-Control requests
        // should be made using credentials
        if (options.withCredentials) config.withCredentials = options.withCredentials || false // default

        // `transformRequest` allows changes to the request data before it is sent to the server
        // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
        // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
        // FormData or Stream
        // You may modify the headers object.
        if (options.transformRequest) config.transformRequest = options.transformRequest || [function (data:any) {
            return data;
        }] // default

        // `transformResponse` allows changes to the response data to be made before
        // it is passed to then/catch
        if (options.transformResponse) config.transformResponse = options.transformResponse || [function (data:any) {
            return data;
        }] // default

        config.headers = options.headers || {};

        // `headers` are custom headers to be sent
        if (options.type == 'form') config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        if (options.type == 'multipart-form') config.headers['Content-Type'] = 'multipart/form-data'
        if (options.type == 'json') config.headers['Content-Type'] = 'application/json'
        if (options.type == undefined) config.headers['Content-Type'] = 'application/x-www-form-urlencoded'


        let auth_key = cookie.get("auth_key")
        if (options.bearer) {
            config.headers['Authorization'] = `Bearer ${options.bearer}`
        } else if ('user/token' in store.getters && store.getters['user/token']) {
            config.headers['Authorization'] = 'Bearer ' + store.getters['user/token']
        } else if (auth_key) {
            config.headers['Authorization'] = 'Bearer ' + auth_key
        }

        if (typeof data != 'string') config.headers['Content-Type'] = `application/json`

        // console.info(`Run api request with options`, config, data)
        return config
    }

    async request(options: any = {}) {
        let config = this.configure(options, {})
        return await this._axios.axios(config)
    }

    async axios(config: any = {}) {
        return await this._axios.request(config)
    }

    async get(url: string, data: any = {}, options: any = {}) {
        if (store && 'user/token' in store.getters && store.getters['user/token']) {
            this._axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['user/token']
        }
        if (typeof (data) == 'object' && data.params == undefined) {
            options.params = data
        } else if (typeof (data) == 'object') {
            options.params = data.params
        }

        let config = this.configure(options, data)
        return await this._axios.get(url, config)
    }

    async post(url: string, data: any = {}, options: any = {}) {
        let config = this.configure(options, data)
        return await this._axios.post(url, data, config)
    }

    async delete(url: string, data: any = {}, options: any = {}) {
        if (store && 'user/token' in store.getters && store.getters['user/token']) {
            this._axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['user/token']
        }

        let config = this.configure(options, data)
        config.method = 'delete'
        config.url = url
        config.data = data
        return await this._axios(config)
    }

    async put(url: string, data = {}, options = {}) {
        let config = this.configure(options, data)
        return await this._axios.put(url, data, config)
    }

    async patch(url: string, data: any = {}, options: any = {}) {
        let config = this.configure(options, data)
        return await this._axios.patch(url, data, config)
    }

    async head(url: string, data: any = {}, options: any = {}) {
        let config = this.configure(options, data)
        return await this._axios.head(url, data, config)
    }

    install(Vue: any, options: any) {
    }
}


let api = new Api()

export default api
