import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import _ from "lodash";

Vue.use(VueI18n)

function loadLocaleMessages(locales: any, mode: string = ''): LocaleMessages {
  const messages: LocaleMessages = {}
  /* if mode received accept only files for this mode Otherwise accept any file with en|ru in filename */
  const matchRegexp = mode ? new RegExp(mode + "\.([en|ru]+)", 'i') : /([en|ru]+)\./i
  locales.keys().forEach(key => {
    const matched = key.match(matchRegexp)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function loadCommonLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /\/[en|ru]+\.json$/i)
  return loadLocaleMessages(locales)
}

function loadCustomLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /\/[A-Za-z0-9]+\.[en|ru]+\.json$/i)
  return loadLocaleMessages(locales, window.__env.mode)
}

function loadAllLocaleMessages(): LocaleMessages {
  return _.merge(loadCommonLocaleMessages(), !window.__env.mode ? null : loadCustomLocaleMessages())
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadAllLocaleMessages()
})
